import React, { ReactElement } from 'react'
import s from './styles.module.scss';

import firebase from 'firebase/app';
import Linkify from 'react-linkify';
import format from 'date-fns/format';
import { nl } from 'date-fns/locale';
import useStore from './store';
import { useEffect } from 'react';
import { useState } from 'react';
import MessageImage from './MessageImage';


const componentDecorator = (decoratedHref: string, decoratedText: string, key: number) => (
  <a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
      {decoratedText}
  </a>
);

interface Props {
  
}



export default function Messages({}: Props): ReactElement {
  const [openImagePopup, setImage, messages, setMessages, setMessagesLoading, messagesLoading, messagesLoadingDone, setMessagesLoadingDone, count, setCount] = useStore((store: any) => [ store.openImagePopup, store.setImage, store.messages, store.setMessages, store.setMessagesLoading, store.messagesLoading, store.messagesLoadingDone, store.setMessagesLoadingDone, store.count, store.setCount]);

  const [isBottom, setIsBottom] = useState(false);

  
  function isScrolling(){
    if(window.innerHeight + document.documentElement.scrollTop <= document.documentElement.offsetHeight - 100){
      return;
    }
    else {
      setIsBottom(true)
    }};


  useEffect(()=>{
    window.addEventListener("scroll", isScrolling);
    return () => window.removeEventListener("scroll", isScrolling);}, [])

  useEffect(() => {
   (async () => {
    const ref = firebase.firestore().collection('stats').doc('messages');
    const doc = await ref.get();
    const value = doc.data()?.value || 0;
    setCount(value);
   })();  
  }, [])

  useEffect(() => {
   (async () => {
      if (messagesLoadingDone || messagesLoading) {
        return;
      }
      
      if (!isBottom && messages.length > 0) {
        return
      }

      setMessagesLoading(true);

      let newMessages = [] as any[];

      try {
        let query: firebase.firestore.Query<firebase.firestore.DocumentData> = await firebase.firestore().collection('messages');

        query = query.where('visible', '==', true);
        query = query.limit(10);
        query = query.orderBy('date', 'desc');

        if (messages.length > 0) {
          const latestId = messages[messages.length - 1].id;
          const latest = await firebase.firestore().collection('messages').doc(latestId).get();
          query = query.startAfter(latest);
        }

        const snapshot = await query.get();

  
        newMessages = snapshot.docs.map(shot => ({
          ...shot.data(),
          id: shot.id,
          date: shot.data().date.toDate(),
        }))

      } catch (error) {
        console.error(error);
      }

      if (newMessages.length > 0) {
        setMessages(newMessages);
      } else {
        // if no left, stop fetching
        setMessagesLoadingDone(true)
      }

      setMessagesLoading(false);
      setIsBottom(false);
   })()
  }, [isBottom])


  return (
    <section className={s.register}>
<h2 className={s.registerTitle}>Berichten {count > 0 && <span className={s.registerTitleCounter}>{count}</span>}</h2>
        <div className={s.registerMessages}>
          {messages.length > 0 ? messages.map((message: any) => (
          <div className={s.registerMessagesMessage} data-id={message.id}>
            <div className={s.registerMessagesMessageTitle}>
              <h4>{message.name}</h4>
              <span>{format(message.date, "'op' d/M/yyyy 'om' H:mm", { locale: nl })}</span>
            </div>
            <Linkify componentDecorator={componentDecorator}>
            <p>{message.text}</p>
            </Linkify>
            <div className={s.registerMessagesMessageImages}>
            {message.images?.map((url: string) => (
              <MessageImage url={url} />
            ))}
            </div>
          </div>
        ))
       : !messagesLoading && (
          <span>Geen berichten gevonden.</span>
        )}
        </div>
        {messagesLoading && (
          <div className={s.registerLoader}>
            <svg
              version="1.1"
              id="L9"
              xmlns={'http://www.w3.org/2000/svg'}
              xmlnsXlink={'http://www.w3.org/1999/xlink'}
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xmlSpace="preserve"
            >
              <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"></path>
            </svg>
          </div>
        )}
      </section>
  )
}
