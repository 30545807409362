import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import cx from 'classnames';

import s from './popup.module.scss';

interface Props {
  onClose?: () => void;
  className?: any;
}
interface State {
  active: boolean;
}

export default class Popup extends Component<Props, State> {
  public static Title = ({ children }: any) => <h2 className={s.popupTitle}>{children}</h2>;
  public static Closer = ({ children, onClick }: any) => (
    <div className={s.popupCloser}>
      <span onClick={onClick} className={s.popupCloserText}>
        {children}
      </span>
    </div>
  );

  public wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();
  // public handleClickOutside: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        event.preventDefault();
        if (this.props.onClose) {
          this.props.onClose();
        }
      }
    });
    this.forceUpdate();
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  public handleClickOutside = (event: MouseEvent) => {
    if (this.wrapperRef) {
      const node = findDOMNode(this.wrapperRef.current);
      if (node && !node.contains(event.target as Node)) {
        if (this.props.onClose) {
          this.props.onClose();
        }
      }
    }
  };

  render() {
    return (
      <div className={cx(s.popup, this.props.className && this.props.className)}>
        <div className={s.popupContainer} ref={this.wrapperRef}>
          <div className={s.popupContainerContent}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
