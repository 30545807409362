import React from 'react';
import foto from './assets/kacper.jpg';
import cx from 'classnames';
import s from './styles.module.scss';

import MessagePopup from './MessagePopup';
import ImagePopup from './ImagePopup';
import useStore from './store';
import Messages from './Messages';

function App() {
  const [isPopupOpen, openPopup, isImagePopupOpen] = useStore((store: any) => [store.isPopupOpen, store.openPopup, store.isImagePopupOpen]);
  return (
    <div className={s.app}>
      <header className={s.header}>
        <img src={foto} className={s.headerImage} alt="Kacper" />
        <div className={s.headerInfo}>
          <h1 className={s.headerInfoName}>
            Kacper "Kastiop" Przybylski
          </h1>
          <h3 className={s.headerInfoDate}>
            12/08/1998 - 24/10/2020
          </h3>
          <span className={s.headerInfoText}>
            Belgische youtube godfather, de snelste ster aan de hemel, maar vooral een topkerel die we allemaal gaan missen. <br/>
            <br/>
            Rust zacht, Kacper.
          </span>
        </div>
      </header>
      <section className={s.intro}>
        <p>We beseffen dat er veel mensen (familie, vrienden, collega’s, fans) het moeilijk hebben met het plotse overlijden van Kastiop. Om iedereen een <strong>mogelijkheid te geven afscheid te nemen van Kacper</strong> kan je op deze pagina <strong>verschillende manieren vinden om je verdriet een plaats te geven.</strong></p>
      </section>
      <section className={s.info}>
        <div className={cx(s.infoBlock, s.infoBlockRegister)}>
          <h2>Rouwregister</h2>
          <p>Laat ons <strong>samen herinneringen aan Kacper oprakelen</strong>. Laat weten wat hij voor jou heeft betekend.</p>
          <p><strong>Voeg gerust foto’s en / of filmpjes toe</strong> waarop je samen met Kacper staat.</p>
          <p>Deel je reactie, blader door reacties van andere fans en geniet van het prachtige dat Kacper (en zijn fans) achterlaat.</p>
          <button type='button' className={s.button} onClick={() => openPopup(true)}>Laat een bericht achter</button>
        </div>
        <div className={cx(s.infoBlock, s.infoBlockAdres)}>
          <h2>Rouwadres</h2>
          <p>Wil je liever een <strong>kaartje sturen</strong> of wil je je zelfgemaakte collage achterlaten bij Kacper en diens mama, dan kan je je brief, kaartje of pakketje opsturen naar het adres hieronder.</p>
          <p>Eenmaal alles verzameld is, zal dit allemaal overhandigd worden aan Kacpers mama.</p>
          <span>META<br/>
          t.a.v. Kastiop<br/>
          Harmoniestraat 52<br/>
          2300 Turnhout</span>
        </div>
      </section>
      <section>
        <div className={s.fundraiser}>
        <div className={s.fundraiserInfo}>
          <h2>Inzamelactie</h2>
          <p>Het is bijzonder pijnlijk om een vriend en collega te zien gaan, maar niemand kan voelen wat Kacpers mama meemaakt. <strong>Geen enkele ouder zou zijn / haar kind moeten begraven.</strong></p>
          <p>Daarom willen wij proberen om haar zorgen zo minimaal mogelijk te houden, zodat zij zich kan focussen op het belangrijkste: een mooi afscheid voor Kacper.</p>
          <p>Wie graag wil <strong>helpen om de financiële en administratieve kosten van zijn overlijden wat draaglijker te maken</strong>, kan een bijdrage achterlaten. Dit bedrag zal dan overhandigd worden aan Kacpers mama, zodat zij hopelijk hiermee geholpen wordt voor de begrafenis.</p>
          <p>Mocht er meer zijn dan nodig, dan zal het resterende bedrag gestort worden naar een organisatie die zich inzet voor de mentale gezondheid van jongeren.</p>
        </div>

        <div className={s.widget} dangerouslySetInnerHTML={{
          __html: `<div>
          <div
          id="rememberkastiop"
          class="widget"
          value="donation-widget"
          data-slug="rememberkastiop"
          data-lang="nl"
          data-card="hide"
          data-success_url=""
          data-fail_url=""
          ></div>
          </div>`
        }}>
        </div>
        </div>
      </section>
      <Messages />
      {isPopupOpen && 
        <MessagePopup />}
      {isImagePopupOpen && 
        <ImagePopup />}
    </div>
  );
}

export default App;
