import Popup from './Popup';
import useStore from './store';
import { Formik } from 'formik';
import s from './styles.module.scss';
import cx from 'classnames';
import Dropzone from 'react-dropzone';

import React, { ReactElement, useState } from 'react'
import Button from './Button';

interface Props {
  
}

export default function ImagePopup({}: Props): ReactElement {
  const [image, openImagePopup] = useStore(store => [store.image, store.openImagePopup]) as [string, (open: boolean) => void];
 
    return (
      <Popup onClose={() => openImagePopup(false)}>
        <div className={s.imagePopup}>
          <img src={image} />
        </div>
          <Popup.Closer onClick={() => openImagePopup(false)}>sluiten</Popup.Closer>
        </Popup>)
}
