import React, { ReactElement } from 'react';
import s from './button.module.scss';

import cx from 'classnames';

interface Props {
  onClick?: () => void;
  text: string;
  type: 'button' | 'submit' | 'reset';
  disabled: boolean;
  className?: any;
}

export default function Button({ onClick, text, type, className, disabled }: Props): ReactElement {
  return (
    <button type={type} className={cx(s.button, className)} onClick={onClick} disabled={disabled}>
      {/* { ( */}
      {!disabled ? (
        <span>{text}</span>
      ) : (
        <div className={s.buttonLoader}>
          <svg
            version="1.1"
            id="L9"
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enable-background="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"></path>
          </svg>
        </div>
      )}
    </button>
  );
}
