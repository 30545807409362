import React, { ReactElement, useState } from 'react'
import s from './styles.module.scss';
import useStore from './store';

interface Props {
    url: string;
}

export default function MessageImage({url}: Props): ReactElement {
  const [setImage, openImagePopup] = useStore(store => [store.setImage, store.openImagePopup]) as [any, any];
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={s.registerMessagesMessageImagesImage}>
         <img src={url} loading={'lazy'} style={{
              opacity: imageLoaded ? 1 : 0,
            }}
            alt="fan foto"
            onLoad={async () => {
              setImageLoaded(true);
              
            }} onClick={() => {
          setImage(url);
          openImagePopup(true);
        }}/>
      {!imageLoaded && (
        <div className={s.registerMessagesMessageImagesImageLoader}>
          <svg
            version="1.1"
            id="L9"
            xmlns={'http://www.w3.org/2000/svg'}
            xmlnsXlink={'http://www.w3.org/1999/xlink'}
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enable-background="new 0 0 0 0"
            xmlSpace="preserve"
            >
            <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"></path>
          </svg>
        </div>
      )}
  </div>
  )
}
