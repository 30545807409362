import create from 'zustand'

const useStore = create(set => ({
  isPopupOpen: false,
  openPopup: (open: boolean) => set({isPopupOpen: open}),
  image: undefined,
  setImage: (image: string) => set({image}),
  isImagePopupOpen: false,
  openImagePopup: (open: boolean) => set({isImagePopupOpen: open}),
  messages: [] as object[],
  setMessages: (messages: object[]) => set((state: any) => 
    ({messages: state.messages.concat(messages.filter((message) => state.messages.indexOf(message) < 0))})),
  insertMessage: (message: object) => set(state => ({messages: [message, ...state.messages as object[]], count: state.count as number + 1})),
  messagesLoading: false,
  setMessagesLoading: (loading: boolean) => set({messagesLoading: loading}),
  messagesLoadingDone: false,
  setMessagesLoadingDone: (done: boolean) => set({messagesLoadingDone: done}),
  count: 0,
  setCount: (count: number) => set({count}),
}));

export default useStore;