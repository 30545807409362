import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/app';
import 'firebase/firestore';   // for cloud firestore
import 'firebase/storage';     // for storage
import 'firebase/analytics';     // for storage


const firebaseConfig = {
  apiKey: "AIzaSyCVIH-FPL24-_tIW6mHV00MprwNGUABqq8",
  authDomain: "rouwregister.firebaseapp.com",
  databaseURL: "https://rouwregister.firebaseio.com",
  projectId: "rouwregister",
  storageBucket: "rouwregister.appspot.com",
  messagingSenderId: "936862121642",
  appId: "1:936862121642:web:818cff1ab9d29d91609ad3",
  measurementId: "G-E0LT9VWYZD"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

console.log('Ik ga u missen mate, rust zacht. ❤️');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
