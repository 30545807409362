import Popup from './Popup';
import useStore from './store';
import { Formik } from 'formik';
import s from './styles.module.scss';
import cx from 'classnames';
import Dropzone from 'react-dropzone';
import firebase from 'firebase/app';

import {v4} from 'uuid';

import React, { ReactElement, useState } from 'react'
import Button from './Button';

import * as Yup from 'yup';

interface Props {
  
}

const messageSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Naam is te kort!')
    .max(50, 'Naam is te lang!')
    .required('Vergeet je naam niet in te vullen!'),
  text: Yup.string()
    .min(2, 'Bericht is te kort!')
    .max(1024, 'Bericht is te lang!')
    .required('Vergeet je bericht niet mee te sturen!'),
  images: Yup.array(Yup.mixed()),
});

export default function MessagePopup({}: Props): ReactElement {
  const [openPopup, insertMessage] = useStore(store => [store.openPopup, store.insertMessage]);
  const [isLoading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState({});

    return (
      <Popup onClose={() => (openPopup as any)(false)}>
          <Popup.Title>Laat een bericht achter</Popup.Title>
          <p className={s.messagePopupInfo}>Laat ons samen <strong>herinneringen aan Kacper oprakelen</strong>. Laat weten wat hij voor jou heeft betekend.</p>
          <p>Wil je graag een filmpje uploaden? Plaats het op youtube en zet de link in je bericht.</p>
          <p>Berichten zullen worden nagekeken alvorens ze in het rouwregister verschijnen.</p>
          <Formik
          initialValues={{ name: '', text: '', images: [] }}
          validationSchema={messageSchema}
          onSubmit={async (values, actions) => {
            const errors: any = {};
            setLoading(true);

            if (values.images.length > 5) {
              errors['images'] = `Foto's zijn gelimiteerd tot 5 per persoon`;
            }


            if (Object.values(errors).length > 0) {
              setLoading(false);
              return actions.setErrors(errors);
            }

            const keepTrying = async (triesRemaining: any, storageRef: any): Promise<string> => {
              if (triesRemaining < 0) {
                return Promise.reject('out of tries');
              }

              try {
                const url = await storageRef.getDownloadURL();
                return url;
              } catch (error) {
                switch (error.code) {
                  case 'storage/object-not-found':
                    await new Promise(res => setTimeout(res, 2000));
                    return keepTrying(triesRemaining - 1, storageRef)
                  default:
                    console.log(error);
                    return Promise.reject(error);
                }
              }
            }
            
            
            const imageUrls = [] as string[];
            await Promise.all(values.images.map(async image => {
              const imageName = `images/${v4()}`;
              let storageRef = firebase.storage().ref(imageName);
              await storageRef.put(image);

              const thumbnailName = `${imageName}_1000x1000`;
              storageRef = firebase.storage().ref(thumbnailName);

              const url = await keepTrying(10, storageRef);
              imageUrls.push(url);
            }));

            const doc = await firebase.firestore().collection('messages').add({
              name: values.name,
              text: values.text,
              images: imageUrls,
              date: new Date(),
              visible: false,
            });

            const increment = firebase.firestore.FieldValue.increment(1);
            const storyRef = firebase.firestore().collection('stats').doc('messages');
            storyRef.update({ value: increment });

            const message = (await doc.get()) as any;

            (insertMessage as any)({
              ...message.data(),
              id: message.id,
              date: message.data().date.toDate()
            })

            setLoading(false);
            (openPopup as any)(false);
            // add to store so it's automatically on the page

            // setFormFields(values);
            // this.setState({ email: values.email });
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <input
                id="name"
                name="name"
                type="name"
                onChange={props.handleChange}
                value={props.values.name}
                placeholder={'Je naam of username'}
                className={cx(s.input, s.mtm, props.errors.name && props.touched.name && s.inputError)}
              />
              {props.errors.name && props.touched.name ? <span className={s.inputErrorLabel}>{props.errors.name}</span> : null}
              <textarea
                id="text"
                name="text"
                onChange={props.handleChange}
                value={props.values.text}
                placeholder={'Je bericht'}
                rows={5}
                className={cx(s.input, s.textArea, s.mtm, props.errors.text && props.touched.text && s.inputError)}
              />
              {props.errors.text && props.touched.text ? <span className={s.inputErrorLabel}>{props.errors.text}</span> : null}

              <Dropzone
                    onDrop={(files: File[]) => {
                      const newFiles = props.values.images as File[];
                      newFiles.push(...files);
                      if (newFiles.length > 5) {
                        return props.setFieldError('images', `Foto's zijn gelimiteerd tot 5 per persoon`);
                      }

                      return props.setFieldValue('images',  newFiles);
                    }}
                    disabled={props.values.images.length > 5}
                    noKeyboard={true}
                    noClick={true}
                    multiple={true}
                    accept={['.png', '.jpg', '.jpeg']}
                  >
                    {({ getRootProps, getInputProps, open, isDragActive }) => (
                      <div {...getRootProps({ className: cx(s.dropzone, props.errors.images && s.dropzoneError, isDragActive && s.dropzoneDragging) })}>
                        {/* {!props.values.file ? ( */}
                          <>
                            <input {...getInputProps()} />
                            
                            <button type="button"  className={s.dropzoneButton} onClick={open}>
                              {!isDragActive ? (
                                <span >Selecteer je foto's door hier te klikken of ze hier te slepen</span>
                              ) : (<span className={s.dropzoneButtonDragging}>Drop ze</span>)}
                            </button>
                            
                          </>
                        {/* ) : (
                          <>
                            <span>{(props.values.file! as File).name}</span>
                            <button type="button" onClick={() => props.setFieldValue('file', null)} className={cx(s.button, s.mtm)}>
                              <span>Verwijder foto</span>
                            </button>
                          </>
                        )} */}
                      </div>
                    )}
                  </Dropzone>
                  {props.errors.images && <span className={s.dropzoneErrorSpan}>{props.errors.images}</span>}
                <div className={s.preview}>
                 {props.values.images.map((image: File) => (
                   <div className={s.previewImage}>
                     <span className={s.previewImageClose} onClick={() => props.setFieldValue('images', props.values.images.filter((fieldImages: File) => image.name !== fieldImages.name ))}><svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 492 492"
      version="1.1"
      viewBox="0 0 492 492"
      xmlSpace="preserve"
      className={cx(s.icon)}
    >
      <path d="M300.188 246L484.14 62.04c5.06-5.064 7.852-11.82 7.86-19.024 0-7.208-2.792-13.972-7.86-19.028L468.02 7.872C462.952 2.796 456.196.016 448.984.016c-7.2 0-13.956 2.78-19.024 7.856L246.008 191.82 62.048 7.872C56.988 2.796 50.228.016 43.02.016c-7.2 0-13.96 2.78-19.02 7.856L7.872 23.988c-10.496 10.496-10.496 27.568 0 38.052L191.828 246 7.872 429.952C2.808 435.024.02 441.78.02 448.984c0 7.204 2.788 13.96 7.852 19.028l16.124 16.116c5.06 5.072 11.824 7.856 19.02 7.856 7.208 0 13.968-2.784 19.028-7.856l183.96-183.952 183.952 183.952c5.068 5.072 11.824 7.856 19.024 7.856h.008c7.204 0 13.96-2.784 19.028-7.856l16.12-16.116c5.06-5.064 7.852-11.824 7.852-19.028 0-7.204-2.792-13.96-7.852-19.028L300.188 246z"></path>
    </svg></span>
                     <img src={URL.createObjectURL(image)} />
                   </div>
                   ))} 
                </div>

              {/* <Button  text="Verifieer" className={s.mtm}  /> */}
              {/* <button } type="submit" disabled={isLoading} >Verzend</button> */}
              <Button disabled={isLoading} text={'Verzend'} type={'submit'} className={cx(s.button, s.formButton)}></Button>
            </form>
          )}
        </Formik>
          <Popup.Closer onClick={() => (openPopup as any)(false)}>sluiten</Popup.Closer>
        </Popup>)
}
